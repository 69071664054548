import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Fragment } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: 'none',
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

/**
 * Example of teams data
 * teams: [
 *  {
 *  teamId:"68d06e1b-ac43-47c7-a6b0-0c08026cfe56"
    teamName:"John Doe, Anna Pieterse"
    playoff:null
    totalPoints:0
    setPoints:0
    gamePoints:0
    net:-13
    round:2
    sets: [...]
    colors: {fColor: "primary", aColor: "secondary"}
 * }
 * ]
 * round: 1 OR undefined
 */

const SectionScoreCard = ({ teams, round }) => {
  let ordered_teams = teams;

  // Sort by `totalScore` first, then by `netTotalScore`
  ordered_teams.sort((a, b) => {
    // Compare totalScore first
    if (b.totalScore !== a.totalScore) {
      return b.totalScore - a.totalScore; // Descending order
    }

    // If totalScore is the same, then compare netTotalScore
    return b.net - a.net; // Descending order
  });

  const setBox = (team) => {
    let _totalFor = 0;
    let _totalAgainst = 0;

    for (let i = 0; i < team.sets.length; i++) {
      const _set = team.sets[i];

      _totalFor += _set.teamTotal;
      _totalAgainst += _set.totalAgainst;
    }

    return (
      <Box display="flex" flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
        <Box display="flex" flexDirection={'column'} alignItems={'center'} p={1}>
          <Typography
            fontSize={10}
            fontWeight={'bold'}
            fontStyle={'italic'}
            color={`${team.colors.fColor}.main`}>
            F
          </Typography>
          <Typography
            fontSize={10}
            fontWeight={'bold'}
            fontStyle={'italic'}
            color={`${team.colors.aColor}.main`}>
            A
          </Typography>
        </Box>
        {team.sets.map((set, index) => (
          <Fragment key={index}>
            <Box display="flex" flexDirection={'column'} alignItems={'center'} p={1}>
              <Typography fontSize={12}>{set.teamTotal}</Typography>
              <Typography fontSize={12}>{set.totalAgainst}</Typography>
            </Box>
          </Fragment>
        ))}
        <Box display="flex" flexDirection={'column'} alignItems={'center'} p={1}>
          <Typography fontSize={12}>({_totalFor})</Typography>
          <Typography fontSize={12}>({_totalAgainst})</Typography>
        </Box>
      </Box>
    );
  };

  const playOffBox = (team) => (
    <Box display="flex" flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
      <Box display="flex" flexDirection={'column'} alignItems={'center'} p={1}>
        <Typography fontSize={12}>{team.playoff.teamTotal}</Typography>
        <Typography fontSize={12}>{team.playoff.totalAgainst}</Typography>
      </Box>
    </Box>
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: 3,
        maxWidth: 400,
        border: '1px solid',
        borderColor: 'primary.main'
      }}>
      <Table padding="none" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell padding="normal" sx={{ fontWeight: 'bold' }}>
              {round ? `Round ${round}` : 'Team'}
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'center'}>
              Score
            </StyledTableCell>
            {/* <StyledTableCell padding="normal" align={'center'}>
              P
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'center'}>
              SP
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'center'}>
              GP
            </StyledTableCell> */}
            <StyledTableCell padding="normal" align={'center'}>
              Total
            </StyledTableCell>
            <StyledTableCell padding="normal" align={'center'}>
              Net
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordered_teams.map((team, index) => (
            <TableRow key={index}>
              <StyledTableCell padding="normal">
                <Typography fontSize={10} fontWeight={'bold'} color={`${team.colors.fColor}.main`}>
                  {team.teamName}
                </Typography>
                <Typography fontSize={10} fontStyle={'italic'} color={`${team.colors.aColor}.main`}>
                  {team.teamAgainstName}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align={'center'}>{setBox(team)}</StyledTableCell>
              {/* <StyledTableCell align={'center'}>
                {team.playoff ? playOffBox(team) : 0}
              </StyledTableCell>
              <StyledTableCell align={'center'}>{`${team.setPoints}`}</StyledTableCell>
              <StyledTableCell align={'center'}>{`${team.gamePoints}`}</StyledTableCell> */}
              <StyledTableCell align={'center'}>{`${team.totalPoints}`}</StyledTableCell>
              <StyledTableCell align={'center'}>{`${team.net}`}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SectionScoreCard;
