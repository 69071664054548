import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import eventService from './eventService';
import { convertFirebaseDate } from '../../helpers/dateHelper';

export const getAllEvents = createAsyncThunk('event/getAllEvents', async (data, thunkAPI) => {
  return await eventService
    .get()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getAllEventsByVenue = createAsyncThunk(
  'event/getAllEventsByVenue',
  async (data, thunkAPI) => {
    return await eventService
      .getAllByVenue(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getEvent = createAsyncThunk('event/getEvent', async (data, thunkAPI) => {
  return await eventService
    .getById(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const createEvent = createAsyncThunk('event/createEvent', async (data, thunkAPI) => {
  return await eventService
    .create(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const updateEvent = createAsyncThunk('event/updateEvent', async (data, thunkAPI) => {
  return await eventService
    .update(data.id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const updateEventTeam = createAsyncThunk('event/updateEventTeam', async (data, thunkAPI) => {
  return await eventService
    .updateTeam(data.eventId, data.team)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const deleteEvent = createAsyncThunk('event/deleteEvent', async (data, thunkAPI) => {
  return await eventService
    .remove(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const addEventGame = createAsyncThunk('event/addEventGame', async (data, thunkAPI) => {
  return await eventService
    .addGame(data.eventId, data.sectionId, data.game)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getCMEvents = createAsyncThunk('event/getCMEvents', async (data, thunkAPI) => {
  return await eventService
    .getCMEvents(data.cmClubId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getCMEvent = createAsyncThunk('event/getCMEvent', async (data, thunkAPI) => {
  return await eventService
    .getCMEvent(data.cmEventId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const updateCMEvent = createAsyncThunk('event/updateCMEvent', async (data, thunkAPI) => {
  return await eventService
    .updateCMEvent(data.cmEventId, data.eventId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const eventSlice = createSlice({
  name: 'events',
  initialState: {
    loading: true,
    event: null,
    events: [],
    addGameLoading: false,
    clubmaster: {}
  },
  reducers: {
    setLoadingEvent: (state, action) => {
      state.loading = action.payload;
    },
    setEvent: (state, action) => {
      state.event = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.events = action.payload;
        state.loading = false;
      })
      .addCase(getAllEvents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllEventsByVenue.fulfilled, (state, action) => {
        state.events = action.payload;
        state.loading = false;
      })
      .addCase(getAllEventsByVenue.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllEventsByVenue.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })
      .addCase(getEvent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
        state.saving = false;
      })
      .addCase(createEvent.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.saving = false;
      })
      .addCase(addEventGame.fulfilled, (state, action) => {
        state.addGameLoading = false;
      })
      .addCase(addEventGame.pending, (state, action) => {
        state.addGameLoading = true;
      })
      .addCase(addEventGame.rejected, (state, action) => {
        state.addGameLoading = false;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.events = state.events.map((event) => {
          if (event.id === action.payload.id) {
            return action.payload;
          }
          return event;
        });
        state.saving = false;
      })
      .addCase(updateEvent.pending, (state, action) => {
        state.saving = true;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.saving = false;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter((event) => event.id !== action.payload.eventId);
        state.loading = false;
      })
      .addCase(deleteEvent.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEventTeam.fulfilled, (state, action) => {
        state.updatingTeam = false;
      })
      .addCase(updateEventTeam.pending, (state, action) => {
        state.updatingTeam = true;
      })
      .addCase(updateEventTeam.rejected, (state, action) => {
        state.updatingTeam = false;
      })
      .addCase(getCMEvents.fulfilled, (state, action) => {
        const convertedEvents = [];
        for (let i = 0; i < action.payload.length; i++) {
          const item = action.payload[i];
          let newItem = item;
          const newDate = convertFirebaseDate(item.startDate);
          newItem.startDate = newDate;
          convertedEvents.push(newItem);
        }

        convertedEvents.sort((a, b) => {
          return a.startDate - b.startDate;
        });

        state.clubmaster.events = convertedEvents;
        state.clubmaster.loadingEvents = false;
      })
      .addCase(getCMEvents.pending, (state, action) => {
        state.clubmaster.loadingEvents = true;
      })
      .addCase(getCMEvents.rejected, (state, action) => {
        state.clubmaster.loadingEvents = false;
      })
      .addCase(getCMEvent.fulfilled, (state, action) => {
        state.clubmaster.event = action.payload;
        state.clubmaster.loadingEvent = false;
      })
      .addCase(getCMEvent.pending, (state, action) => {
        state.clubmaster.loadingEvent = true;
      })
      .addCase(getCMEvent.rejected, (state, action) => {
        state.clubmaster.loadingEvent = false;
      })
      .addCase(updateCMEvent.fulfilled, (state, action) => {
        console.log(action.payload);
        state.clubmaster.updatingEvent = false;
      })
      .addCase(updateCMEvent.pending, (state, action) => {
        state.clubmaster.updatingEvent = true;
      })
      .addCase(updateCMEvent.rejected, (state, action) => {
        state.clubmaster.updatingEvent = false;
      });
  }
});

export const { setEvent, setLoadingEvent } = eventSlice.actions;

export default eventSlice.reducer;
