import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useState } from 'react';
import { deleteGame } from '../../../features/game/gameSlice';
import EditGame from '../../games/editGame';
import { useAuth } from '../../../contexts/authContext';
import { hasAccess, Roles } from '../../../helpers/access';
import AssignScorer from '../../games/assignScorer';

const GameCardBase = ({ children, game, actions }) => {
  const dispatch = useDispatch();
  const deleting = useSelector((state) => state.game.deleting);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const { authenticated, currentUserProfile } = useAuth();
  const role = currentUserProfile?.role;

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEdit = () => {
    setOpenEdit(true);
  };

  const handleDelete = (game) => {
    setSelectedGame(game);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setSelectedGame(null);
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteGame(selectedGame.id))
      .unwrap()
      .then(() => {
        setSelectedGame(null);
        setOpenDelete(false);
      });
  };

  const setBorder = () => {
    if (game.live) {
      return { border: 'solid 2px', borderColor: 'red.main' };
    } else if (game.featured) {
      return { border: 'solid 2px', borderColor: 'yellow.main' };
    } else {
      return null;
    }
  };

  const setTag = () => {
    if (game.live) {
      return (
        <Box
          position="absolute"
          top={0}
          right={0}
          backgroundColor="red.main"
          p={'5px'}
          fontSize={10}
          color={'background.main'}
          display="flex"
          sx={{ borderRadius: '0 13px 0 13px' }}>
          {game.live && 'Live'}
        </Box>
      );
    } else if (game.featured) {
      return (
        <Box
          position="absolute"
          top={0}
          right={0}
          backgroundColor="yellow.main"
          p={'5px'}
          fontSize={10}
          color={'background.main'}
          display="flex"
          sx={{ borderRadius: '0 13px 0 13px' }}>
          {game.featured && 'Featured'}
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: 450,
        p: 3,
        borderRadius: 5,
        ...setBorder(),
        position: 'relative'
      }}
      elevation={3}>
      {setTag()}
      <Stack justifyContent={'space-between'} height="100%">
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}>
          <Typography textAlign={'center'}>{game.name ? game.name : 'Private Game'}</Typography>

          {actions && hasAccess(currentUserProfile, Roles.ADMIN) && (
            <Box>
              <IconButton size="small" color="error" onClick={() => handleDelete(game)}>
                <Delete />
              </IconButton>
              <IconButton size="small" color="info" onClick={() => handleEdit(game)}>
                <Edit />
              </IconButton>
            </Box>
          )}
        </Box>

        <EditGame game={game} open={openEdit} onClose={handleEditClose} />

        {children}

        {hasAccess(currentUserProfile, Roles.SUPERUSER) && (
          <Typography variant="caption" sx={{ alignSelf: 'flex-end', mt: 2 }}>
            Game ID: {game.id}
          </Typography>
        )}

        {actions && hasAccess(currentUserProfile, Roles.ADMIN) ? (
          game.scorer ? (
            <Box display="flex" justifyContent={'flex-end'} alignItems={'center'} gap={1}>
              <Typography variant="caption">
                Scorer: {game.scorer.firstName} {game.scorer.lastName}
              </Typography>
              <AssignScorer game={game} scorer={game.scorer} />
            </Box>
          ) : (
            <AssignScorer game={game} />
          )
        ) : null}
      </Stack>

      {selectedGame && (
        <Dialog
          open={openDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Delete Game</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the game:{' '}
              {selectedGame.name ? selectedGame.name : 'Private Game'}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {deleting ? (
              <CircularProgress />
            ) : (
              <>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                  Confirm
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};

export default GameCardBase;
