// React
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// UI
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import TrashIcon from '@mui/icons-material/DeleteOutlined';

// Services
import gameFormatService from '../../features/gameFormat/gameFormatService';
import PlayerViewDialog from '../players/playerDialog';
import TeamDialog from '../teams/teamDialog';

// Icons
import PlayerIcon from '@mui/icons-material/Person';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ImportPlayerDialog from '../players/importPlayerDialog';
import { removePlayerFromEvent } from '../../features/player/playerSlice';
import { useAlert } from '../../contexts/alertContext';
import { removeTeamFromEvent } from '../../features/team/teamSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventPlayerSetupDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showAlertSuccess, showAlertError } = useAlert();

  const [open, setOpen] = useState(false);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [openTeam, setOpenTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const event = useSelector((state) => state.event.event);
  const isSingles = event.gameFormat.gameType === gameFormatService.GameType.SINGLES;

  const [removeConfirmationDialogOpen, setRemoveConfirmationDialogOpen] = React.useState(false);
  const [removeTeamConfirmationDialogOpen, setRemoveTeamConfirmationDialogOpen] =
    React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlayerAdd = (player) => {
    setSelectedPlayer();
    setOpenPlayer(true);
  };

  const handlePlayerEdit = (player) => {
    setSelectedPlayer(player);
    setOpenPlayer(true);
  };

  const handleTeamAdd = (player) => {
    setSelectedTeam();
    setOpenTeam(true);
  };

  const handleTeamEdit = (team) => {
    setSelectedTeam(team);
    setOpenTeam(true);
  };

  const handleRemoveConfirmationDialogOpen = (player) => {
    setSelectedPlayer(player);
    setRemoveConfirmationDialogOpen(true);
  };

  const handleRemoveConfirmationDialogClose = () => {
    setRemoveConfirmationDialogOpen(false);
  };

  const onPlayerRemove = async () => {
    dispatch(removePlayerFromEvent({ eventId: event.id, playerId: selectedPlayer.id }));
    handleRemoveConfirmationDialogClose();
  };

  const handleRemoveTeamConfirmationDialogOpen = (team) => {
    setSelectedTeam(team);
    setRemoveTeamConfirmationDialogOpen(true);
  };

  const handleRemoveTeamConfirmationDialogClose = () => {
    setRemoveTeamConfirmationDialogOpen(false);
  };

  const onTeamRemove = async () => {
    dispatch(removeTeamFromEvent({ eventId: event.id, teamId: selectedTeam.id }));
    handleRemoveTeamConfirmationDialogClose();
  };

  return (
    <Fragment>
      <Fab size="small" color="primary" aria-label="add" onClick={handleOpen} variant="extended">
        <PlayerIcon />
        Players
      </Fab>
      <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Player & Team Setup
            </Typography>
            <ImportPlayerDialog />
            <PrimaryButton autoFocus color="inherit" onClick={handleClose}>
              Close
            </PrimaryButton>
          </Toolbar>
        </AppBar>

        <Box display={'flex'} justifyContent={'space-evenly'} sx={{ p: 2 }}>
          <Box display={'flex'} flexDirection={'column'} flexGrow={1} mx={1} my={2}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h4">Players</Typography>
              <PrimaryButton variant={'outlined'} onClick={handlePlayerAdd} color="secondary">
                Add Player
              </PrimaryButton>
            </Box>

            <Box display="flex" flexDirection="column">
              {event.players?.length > 0 ? (
                event.players.map((player, index) => (
                  <List dense>
                    <ListItem key={player.id} dense>
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width="100%">
                        <Typography>
                          {player.name} {player.surname}
                        </Typography>
                        <Box>
                          <IconButton onClick={() => handlePlayerEdit(player)}>
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton onClick={() => handleRemoveConfirmationDialogOpen(player)}>
                            <TrashIcon color="error" />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  </List>
                ))
              ) : (
                <Typography variant="caption">No Players Found, add a payer...</Typography>
              )}
            </Box>
          </Box>

          {!isSingles && (
            <Box display={'flex'} flexDirection={'column'} flexGrow={2} mx={1} my={2}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant="h4">Teams</Typography>
                <PrimaryButton variant={'outlined'} onClick={handleTeamAdd} color="secondary">
                  Add Team
                </PrimaryButton>
              </Box>

              <Box display="flex" flexDirection="column">
                {event.teams?.length > 0 ? (
                  <List dense>
                    {event.teams.map((team, index) => (
                      <ListItem key={team.id} dense>
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          width="100%">
                          <Typography>{`${index + 1}. ${team.name}`}</Typography>
                          <Box>
                            <IconButton onClick={() => handleTeamEdit(team)}>
                              <EditIcon color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleRemoveTeamConfirmationDialogOpen(team)}>
                              <TrashIcon color="error" />
                            </IconButton>
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="caption">No Teams Found, add a team...</Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <PlayerViewDialog
          event={event}
          player={selectedPlayer}
          open={openPlayer}
          setOpen={setOpenPlayer}
        />

        <TeamDialog open={openTeam} setOpen={setOpenTeam} selectedTeam={selectedTeam} />
      </Dialog>

      <Dialog
        open={removeConfirmationDialogOpen}
        onClose={handleRemoveConfirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Your are about to delete this player'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this player?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {selectedPlayer?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveConfirmationDialogClose}>Cancel</Button>
          <Button onClick={onPlayerRemove} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={removeTeamConfirmationDialogOpen}
        onClose={handleRemoveTeamConfirmationDialogClose}
        aria-labelledby="alert-dialog-title-team"
        aria-describedby="alert-dialog-description-team">
        <DialogTitle id="alert-dialog-title-team">
          {'Your are about to delete this team'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-team">
            Are you sure you want to delete this team?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description-team">
            {selectedTeam?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveTeamConfirmationDialogClose}>Cancel</Button>
          <Button onClick={onTeamRemove} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default EventPlayerSetupDialog;
