import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../features/user/userSlice';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form from '../ui/forms/index';
import Input from '../ui/forms/fields/input';
import { assignScorer, removeScorer } from '../../features/game/gameSlice';
import BSCircularProgress from '../ui/progress/circularProgress';

import TrashIcon from '@mui/icons-material/Close';
import PhoneNumberInput from '../ui/forms/fields/phoneNumberInput';

const schema = yup.object().shape({
  phone: yup.string().required('Please enter a phone number.'),
  email: yup.string().email('Please enter a valid email address.').optional()
});

const AssignScorer = ({ game, scorer }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openResult, setOpenResult] = useState(false);

  const assigningScorer = useSelector((state) => state.game.assigningScorer);
  const assignResult = useSelector((state) => state.game.assignResult);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    return reset();
  }, [reset]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenResult = () => {
    setOpenResult(true);
  };

  const handleCloseResult = () => {
    setOpenResult(false);

    if (assignResult.success) {
      handleClose();
    }
  };

  const onSubmit = (data) => {
    data.gameId = game.id;

    dispatch(assignScorer(data))
      .unwrap()
      .then((result) => {
        handleOpenResult();
      });
  };

  const handleRemoveScorer = () => {
    dispatch(removeScorer({ gameId: game.id }));
  };

  return (
    <Box display="flex" justifyContent={'flex-end'} alignItems={'center'}>
      <Button
        onClick={handleOpen}
        color="success"
        size={'small'}
        sx={{ fontSize: '0.8rem', py: 0, px: '2px' }}>
        {scorer ? 'Change' : 'Assign Scorer'}
      </Button>
      {scorer && (
        <Tooltip title="Remove Scorer">
          <IconButton size="small" color="error" onClick={() => handleRemoveScorer(scorer)}>
            <TrashIcon fontSize="0.8rem" />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Assign Scorer to {game.name !== '' ? game.name : 'game'}</DialogTitle>
        <DialogContent>
          <Typography>
            Assign a scorer to this game. The scorer will receive an SMS with a link to access
            Bowlscout. If the user does not exists, they will be invited to join Bowlscout.
          </Typography>
          <Form>
            <Box display="flex" flexDirection={'column'} my={1}>
              <PhoneNumberInput
                control={control}
                name="phone"
                label="Phone Number"
                error={errors.phone ? true : false}
                helperText={errors.phone ? errors.phone.message : ''}
                defaultValue={'+27'}
              />
              <Input
                name="email"
                label="Email (optional)"
                error={errors.email}
                helperText={errors.email?.message}
                placeholder={'bob@example.co.za'}
                {...register('email')}
              />
              {assigningScorer ? (
                <Box display="flex" justifyContent={'flex-end'} gap={2}>
                  <BSCircularProgress color={'success'} />
                </Box>
              ) : (
                <Box display="flex" justifyContent={'flex-end'} gap={2}>
                  <Button onClick={handleClose} variant="outlined" color="success" sx={{ mt: 2 }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="success"
                    sx={{ mt: 2 }}>
                    Assign and Send Invite
                  </Button>
                </Box>
              )}
            </Box>
          </Form>
        </DialogContent>
      </Dialog>

      {/* Display alert result of assigning a user, could be an error or success.  */}
      {assignResult && (
        <Dialog open={openResult} onClose={handleCloseResult} fullWidth maxWidth="sm">
          <DialogTitle color={assignResult.success ? 'success' : 'error'}>
            {assignResult.success ? 'Success!' : 'There was an error.'}
          </DialogTitle>
          <DialogContent>
            <Typography>{assignResult && assignResult.message}</Typography>
            <Button onClick={handleCloseResult} variant="outlined" color="success" sx={{ mt: 2 }}>
              Close
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default AssignScorer;
