/* eslint-disable react-hooks/exhaustive-deps */
import { Box, MenuItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Select from '../ui/forms/fields/select';
import TeamColorPicker from '../ui/teamControls/teamColorPicker';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

const EventMultipleVs = () => {
  const eventTeams = useSelector((state) => state.event.event.teams);

  const [teamA, setTeamA] = useState({ players: [], color: 'green' });
  const [teamB, setTeamB] = useState({ players: [], color: 'red' });
  const [teams, setTeams] = useState({});

  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register('teams');
  }, [register]);

  useEffect(() => {
    setTeams({ teamA, teamB });
  }, [teamA, teamB]);

  useEffect(() => {
    setValue('teams', teams);
  }, [teams, setValue]);

  const watchTeamAColor = watch('teamAColor');
  const watchTeamBColor = watch('teamBColor');

  useEffect(() => {
    setTeamA({ ...teamA, color: watchTeamAColor });
  }, [watchTeamAColor]);

  useEffect(() => {
    setTeamB({ ...teamB, color: watchTeamBColor });
  }, [watchTeamBColor]);

  const watchTeamA = watch('teamA');
  const watchTeamB = watch('teamB');

  useEffect(() => {
    if (watchTeamA) {
      onTeamChange(watchTeamA, 'teamA');
    }
  }, [watchTeamA]);

  useEffect(() => {
    if (watchTeamB) {
      onTeamChange(watchTeamB, 'teamB');
    }
  }, [watchTeamB]);

  const onTeamChange = (value, team) => {
    // Find team in eventTeams
    const selectedTeam = eventTeams.find((team) => team.id === value);

    // Convert members to array of name + surname strings
    const players = selectedTeam.members.map((member) => `${member.name} ${member.surname}`);

    if (team === 'teamA') {
      // Set teamA
      setTeamA({ ...teamA, players, id: selectedTeam.id });
    } else {
      // Set teamB
      setTeamB({ ...teamB, players, id: selectedTeam.id });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems={'center'}
      gap={1}>
      <Select name="teamA" control={control} error={errors.teamA}>
        {eventTeams &&
          [...eventTeams]
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort the teams by name
            .map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
      </Select>
      <TeamColorPicker name={'teamAColor'} defaultValue={'green'} />
      <Typography variant="h6" color="primary" fontWeight="bold" sx={{ mx: 2 }}>
        VS
      </Typography>
      <Select name="teamB" control={control} error={errors.teamB}>
        {eventTeams &&
          eventTeams.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.name}
            </MenuItem>
          ))}
      </Select>
      <TeamColorPicker name={'teamBColor'} defaultValue={'red'} />
    </Box>
  );
};

export default EventMultipleVs;
