import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography
} from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';

import AddGameDialog from '../../games/addGameDialog';

import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../../config/firebase';

const EventSectionCard = ({ event, section, onViewSection }) => {
  const dispatch = useDispatch();
  const { authenticated, currentUserProfile } = useAuth();
  const [loadingSection, setLoadingSection] = useState(false);
  const [games, setGames] = useState([]);

  // Get games based on event and section.
  useEffect(() => {
    setLoadingSection(true);

    // Get games where event = event.id and section = section.id
    const gamesRef = collection(store, 'games');
    const gamesQuery = query(
      gamesRef,
      where('event', '==', event.id),
      where('section', '==', section.id)
    );

    const unsubscribe = onSnapshot(gamesQuery, (querySnapshot) => {
      const gamesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setGames(gamesList);
      setLoadingSection(false);
    });

    return () => unsubscribe();
  }, [event, section, dispatch]);

  const getAllPlayersInSection = () => {
    const teams = event.teams;
    const teamPlayerNames = [];

    if (games && games.length > 0) {
      for (let i = 0; i < games.length; i++) {
        const game = games[i];

        const teamA = teams.find((t) => t.id === game.teamA);
        const teamB = teams.find((t) => t.id === game.teamB);

        if (teamPlayerNames.indexOf(teamA.name) === -1) teamPlayerNames.push(teamA.name);
        if (teamPlayerNames.indexOf(teamB.name) === -1) teamPlayerNames.push(teamB.name);
      }
    }

    return teamPlayerNames;
  };

  return (
    <Fragment>
      <Card sx={{ minWidth: 350, mb: 1, mr: 2 }}>
        <CardHeader title={section.name} />
        <CardContent>
          {!loadingSection && games ? (
            <Fragment>
              <Typography variant={'h6'}>Players:</Typography>
              {getAllPlayersInSection().map((player, index) => (
                <Typography key={index} variant="body2">
                  {index + 1}. {player}
                </Typography>
              ))}
            </Fragment>
          ) : (
            loadingSection && <CircularProgress />
          )}
        </CardContent>
        <CardActions
          sx={{ justifyContent: 'space-between', alignItems: 'center', justifySelf: 'flex-end' }}>
          {!loadingSection && games && (
            <Typography variant={'caption'}>Total Games: {games ? games.length : 0}</Typography>
          )}

          <Box display={'flex'} flexDirection={'row'}>
            {hasAccess(currentUserProfile, Roles.ADMIN) && (
              <AddGameDialog event={event} section={section} />
            )}
            {!loadingSection && games && games.length > 0 ? (
              <PrimaryButton color="info" onClick={() => onViewSection()}>
                View Games
              </PrimaryButton>
            ) : null}
          </Box>
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default EventSectionCard;
