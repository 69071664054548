import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './hoc/publicRoute';
import Home from './components/home';
import SignIn from './components/account/signin';
import Dashboard from './components/dashboard';
import PrivateRoute from './hoc/privateRoute';
import Events from './components/events';
import Event from './components/events/event';
import Section from './components/section';
import SignUp from './components/account/signup';
import NewGame from './components/games/newGame';
import Scoring from './components/games/scoring';
import Scoreboard from './components/scoreboard';
import EmbeddedSectionsView from './components/embedded/sections';
import EmbeddedSectionsGames from './components/embedded/sectionGames';
import ForgotPassword from './components/account/forgotPassword';
import { Roles } from './helpers/access';
import Scorecard from './components/scoreboard/scorecard';
import EmbeddedDetailedSectionsView from './components/embedded/sectionsDetailed';
import UserProfileCreate from './components/account/userProfileCreate';
import Log from './components/scoreboard/log';
import EmbeddedScores from './components/embedded/embeddedEventScores';
import Users from './components/users';
import Invite from './components/account/invite';
import PhoneAuth from './components/account/phoneAuth';
import ProfileRoute from './hoc/profileRoute';
import PublicScoreView from './components/games/publicScoreView';

// import PrivateRoute from './hoc/privateRoute';
// import PublicRoute from './hoc/publicRoute';

//Auth
// import ForgotPassword from './components/account/forgotPassword';
// import SignIn from './components/account/signin';
// import SignUp from './components/account/signup';
// import UpdatePassword from './components/account/updatePassword';

const MainRoutes = (props) => {
  return (
    <Routes>
      <Route element={<PrivateRoute role={Roles.USER} />}>
        <Route path={'/dashboard'} element={<Dashboard />} />

        <Route path={'/new'} element={<NewGame />} />
        <Route path={'/events'} element={<Events />} />
        <Route path={'/events/:eventId'} element={<Event />} />
        <Route path={'/events/:eventId/section/:sectionId'} element={<Section />} />
        <Route path={'/events/:eventId/scoreboard'} element={<Scoreboard />} />
        <Route path={'/events/:eventId/log'} element={<Log />} />
        <Route path={'/events/:eventId/scorecard'} element={<Scorecard />} />
        <Route path={'/game/scoring/:gameId'} element={<Scoring />} />
      </Route>

      <Route element={<PrivateRoute role={Roles.ADMIN} />}>
        <Route path={'/users'} element={<Users />} />
      </Route>

      <Route element={<PublicRoute />}>
        <Route path={'/'} element={<Home />} />
        <Route path={'/signin'} element={<SignIn />} />
        <Route path={'/signup'} element={<SignUp />} />
        <Route path={'/phone-auth'} element={<PhoneAuth />} />
        <Route path={'/create-profile'} element={<UserProfileCreate />} />
        <Route path={'/invite/:inviteToken'} element={<Invite />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />

        <Route path={'/embed/event/:eventId'} element={<EmbeddedScores />} />
        <Route path={'/embed/games/:sectionId'} element={<EmbeddedSectionsGames />} />
        <Route path={'/embed/games/:sectionId/:gameId'} element={<PublicScoreView />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
